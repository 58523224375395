import React, { useState } from 'react';


const LanguageIcon = ({ Icon, Name }) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  return (
    <div className="icon-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      {Icon}
      <div className={`popup ${hovered ? 'visible' : ''}`}>{Name}</div>
    </div>
  );
};

export default LanguageIcon;