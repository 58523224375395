import React, { useEffect, useState } from 'react';
import Navbar from './Navbar';
import Stars from './Stars'; 
import Home from './Home';
import Page2 from './Page2';
import Project from './Projects';
import MoreProjects from './MoreProjects';
import Footer from './Footer';
import "./App.css";

const App = () => {
  const [isTouchScreen, setIsTouchScreen] = useState(false);

  useEffect(() => {
    const checkTouchScreen = () => {
      const mediaQuery = window.matchMedia('(pointer: coarse)');
      setIsTouchScreen(mediaQuery.matches);
    };

    checkTouchScreen(); // Initial check
    window.addEventListener('resize', checkTouchScreen); // Listen for changes on window size

    return () => {
      window.removeEventListener('resize', checkTouchScreen); // Clean up the listener
    };
  }, []);
  return (
    <div>
      <Navbar />
      <Stars />

      <div id="home"><Home /></div>
      <div id="about"><Page2 /></div>
      <div class="project-container">
      <div id="Projects">
      <h1 class="project-intro">Projects</h1>
      <Project
        index="1"
        title="Nagogy Rat"
        imageSrc="nagierat.png"
        description="Nagogy Rat is a paid, hidden-source remote access trojan (RAT) designed for educational purposes. With 40+ commands and functionalities, it offers a powerful toolset for learning about cybersecurity and ethical hacking in a controlled environment. Please note that it should be used responsibly and legally."
        languages={['C++']}
        link="https://shop.primeoak.xyz/product/Nagogy-Rat"
      />
      <Project
        index="2"
        title="Multi Tools"
        imageSrc="multitool.png"
        description="Nagogy multi tool a C++ program, a free and open-source collection of 20+ powerful tools! From ASCII art to crypto-to-currency conversion, countdown timers, Discord features, IP scanning, password generation, weather info, and more – we've got you covered! Enjoy the versatility and ease of use in one dynamic package."
        languages={['C++']}
        link="https://github.com/primeoak/Multi-tools"
      />
      <Project
        index="3"
        title="Pycleaner V2"
        imageSrc="pycleaner.png"
        description="PyCleaner is a feature-rich Windows optimizer with over 30 tools to speed up your PC, improve performance, and clean up legacy clutter. Its user-friendly interface ensures a seamless experience while boosting your computer's speed and efficiency. Say hello to a faster and smoother PC with PyCleaner."
        languages={['Python', 'Js', 'HTML', "CSS"]}
      />
      <Project
        index="4"
        title="Nagogy Grabber"
        imageSrc="grabber.png"
        description="Nagogy Grabber is a powerful virus that steals passwords, credit cards, cookies, browsing history from 20+ browsers and apps. It also targets anti-virus software, takes screenshots, captures Roblox cookies, Wi-Fi passwords, system info. Comes with a cool HTML UI and is fully undetectable (FUD). "
        languages={['C++', 'HTML', "Js"]}
        link="https://shop.primeoak.xyz/product/Nagogy-Grabber"
      />
      <Project
        index="5"
        title="DreamyPlants"
        imageSrc="Plants.png"
        description="DreamyPlants is a website with over 50+ plants with description and image with prices was made to practice frontend. It has a cool ui with some popups when clicked."
        languages={["Js", "HTML", "CSS"]}
        link="https://dreamyplants.onrender.com/"
      />
      </div>
      </div>
      <MoreProjects />
      <Footer />
    </div>
  );
};

export default App;
