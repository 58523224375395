import React from 'react';
import './Page2.css';
const Page2 = () => {
  const calculateAge = (birthDate) => {
    const today = new Date();
    const birthDateObj = new Date(birthDate);
    let age = today.getFullYear() - birthDateObj.getFullYear();
    const monthDiff = today.getMonth() - birthDateObj.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birthDateObj.getDate())) {
      age--;
    }
    return age;
  };

  const birthDate = '2007-01-11';
  const age = calculateAge(birthDate);
  return (
    <div className="scrollable-screen">
      <div className="centered-text">
        <div className="content">
          <img src="aboutme.png" alt="Image" className="left-image" />
          <div>
            <h1 className="aboutme">About me</h1>
            <p>Hey, I'm Fadi, known as "Primeoak" and "Dreamyoak" a {age}-year-old developer with a strong passion for coding, especially in C++. I've already completed over 60 impressive projects, and I love taking on complex challenges with a cool head and a good sense of humor. Besides coding, I'm also into exploring cybersecurity, creating 10+ self-made malwares and hacking ventures. My goal is to become a seasoned software engineer, and I'm excited about the journey ahead! 👋</p>
          </div>
        </div>
      </div>
    </div>
    );
  };

export default Page2;
