import React, { useState, useEffect } from "react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar, faCodeBranch } from "@fortawesome/free-solid-svg-icons";
import "./MoreProjects.css"; // Import the CSS file

const RepoCard = ({ repo, openModal }) => {
  const repoNameWithSpaces = repo.name.replace(/-/g, ' ');

  // Function to format the last updated time
  const formatLastUpdatedTime = (lastUpdated) => {
    const now = new Date();
    const updatedTime = new Date(lastUpdated);
    const timeDiff = now.getTime() - updatedTime.getTime();
    const seconds = Math.floor(timeDiff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
      return `Updated ${days} day${days > 1 ? 's' : ''} ago`;
    } else if (hours > 0) {
      return `Updated ${hours} hour${hours > 1 ? 's' : ''} ago`;
    } else if (minutes > 0) {
      return `Updated ${minutes} minute${minutes > 1 ? 's' : ''} ago`;
    } else {
      return `Updated just now`;
    }
  };

  return (
    <div className="repo-card">
      <div className="repo-info">
        <a
          href={repo.html_url}
          target="_blank"
          rel="noopener noreferrer"
          className="repo-name-link"
        >
          <h3>{repoNameWithSpaces}</h3>
        </a>
        {repo.language && <p className="language">{repo.language}</p>}
      </div>
      {repo.description && <p className="desc">{repo.description}</p>}<br/>
      <p>
        <a href={`${repo.html_url}/stargazers`} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faStar} /> {repo.stargazers_count}&nbsp;&nbsp;&nbsp;
        </a>{" "}
        <a href={`${repo.html_url}/network/members`} target="_blank" rel="noopener noreferrer">
          <FontAwesomeIcon icon={faCodeBranch} /> {repo.forks_count}&nbsp;&nbsp;&nbsp;
        </a>{" "}
        {formatLastUpdatedTime(repo.updated_at)}
      </p>
    </div>
  );
};

// Rest of your code remains unchanged



const GitHubRepos = () => {
  const [repos, setRepos] = useState([]);
  const [visibleRepos, setVisibleRepos] = useState(3);
  const [showAll, setShowAll] = useState(false);
  const [modalRepo, setModalRepo] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    axios
      .get("https://api.github.com/users/primeoak/repos")
      .then((response) => {
        setRepos(response.data);
      })
      .catch((error) => {
        console.error("Error fetching repositories:", error);
      });
  }, []);

  const openModal = (repo, event) => {
    event.preventDefault(); // Prevent default behavior
    event.stopPropagation(); // Stop event propagation
    setModalRepo(repo);
  };
  
  
  const closeModal = () => {
    setModalRepo(null);
  };

  const filteredRepos = repos.filter(repo =>
    repo.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  return (
    <div>
      <div data-aos="fade-up">
        <h2 className="section-title">Open-Source Projects</h2>
      </div>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search projects..."
          value={searchTerm}
          onChange={(e) => setSearchTerm((e.target.value).replace(" ","-"))}
        />
      </div>
      <div className="repo-grid">
        {filteredRepos.slice(0, showAll ? filteredRepos.length : visibleRepos).map((repo, index) => (
          <RepoCard key={repo.id} repo={repo} openModal={openModal} />
        ))}
      </div>
      {!showAll && (
        <div className="buttons-container">
          <button className="show-button" onClick={() => setShowAll(true)}>
            Show More
          </button>
        </div>
      )}
      {showAll && (
        <div className="buttons-container">
          <button className="show-button" onClick={() => setShowAll(false)}>
            Show Less
          </button>
        </div>
      )}
      {modalRepo && (
        <div className="overlay" onClick={closeModal}>
          <div className="modal">
            <h3>{modalRepo.name}</h3>
            {modalRepo.description && <p>{modalRepo.description}</p>}
            <p>
              <FontAwesomeIcon icon={faStar} /> {modalRepo.stargazers_count}{" "}
              <FontAwesomeIcon icon={faCodeBranch} /> {modalRepo.forks_count}{" "}
              Last Updated: {modalRepo.updated_at}
            </p>
            {modalRepo.language && <p className="language">Language: {modalRepo.language}</p>}
          </div>
        </div>
      )}
    </div>
  );
};

export default GitHubRepos;
