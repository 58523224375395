import React, { useEffect, useState } from 'react';
import './Stars.css';

const Stars = () => {
  const numStars = 195;

  const [stars, setStars] = useState([]);

  useEffect(() => {
    const initializeStars = () => {
      const starsArr = [];
      for (let i = 0; i < numStars; i++) {
        const star = {
          left: Math.random() * 100 + '%',
          top: Math.random() * 100 + '%',
          size: Math.floor(Math.random() * 3) + 'px',
          animationDuration: Math.floor(Math.random() * 5) + 5 + 's',
        };
        starsArr.push(star);
      }
      setStars(starsArr);
    };

    initializeStars();

    const handleResize = () => {
      initializeStars();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className="stars-container">
      {stars.map((star, index) => (
        <div
          key={index}
          className="star"
          style={{
            left: star.left,
            top: star.top,
            width: star.size,
            height: star.size,
            animationDuration: star.animationDuration,
          }}
        />
      ))}
    </div>
  );
};

export default Stars;
