import React, { useState } from 'react';
import './LanguageIcon.css';

const LanguageIcon = ({ svg, langName}) => {
  const [hovered, setHovered] = useState(false);

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const iconStyle = {
    '--icon-color': "NONE",
  };

  return (
    <div className="icon-wrapper" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
      <div className={`popup ${hovered ? 'visible' : ''}`}>{langName}</div>
      <div style={iconStyle}>
        {React.cloneElement(svg)}
      </div>
    </div>
  );
};

export default LanguageIcon;
